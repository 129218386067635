@each $color, $value in $theme-colors {
  .btn-hover-text-#{$color} {
    &:hover,
    &:active {
      color: $value !important;
    }
  }
}

.btn-wide {
  padding-left: 50px;
  padding-right: 50px;
}

.display-fix {
  margin-left: -4px;
}

.btn-pill {
  border-radius: 100px;
}

.lead-lg {
  font-size: 35px;
}

.radius-0 {
  border-radius: 0;
}

.text-spacey {
  line-height: 28px;
}

.display-1 {
  @include media-breakpoint-down(sm) {
    font-size: 4rem;
  }
}

.display-2 {
  @include media-breakpoint-down(sm) {
    font-size: 3.5rem;
  }
}
